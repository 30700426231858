<template>
  <div :class="getTypePayment == 2 || this.isProvider == 2 ? 'container' : 'container-stripe'">
    <div v-if="getMsgError" class="error-payment" style="margin: 0 auto; margin-bottom: 35px;">
      <div>
        <img class="error-payment__img" src="/images/icn-warning.png" />
      </div>
      <div class="error-payment__text">
        <template v-if="getTxtErrorState == 1">
          Ocurrió un error al procesar el pago.<br /><br />
          <span class="error-payment__text__bold">Respuesta del banco emisor: </span>
          <br />Fondos insuficientes.
        </template>
        <template v-else-if="getTxtErrorState == 2">
          Ocurrió un error al procesar el pago.<br /><br />
          <span class="error-payment__text__bold">Respuesta del banco emisor: </span>
          <br />Los datos introducidos de la tarjeta son incorrectos. Por favor revisa la información y vuelve a
          intentar.
        </template>
        <template v-else-if="getTxtErrorState == 3">
          Ocurrió un error al procesar el pago.<br /><br />
          <span class="error-payment__text__bold">Posibles soluciones: </span>
          <br /><br />

          <span>-</span>&nbsp;Asegúrate de que tu tarjeta esté habilitada para compras en línea.<br />

          <span>-</span>&nbsp;Asegúrate de que tu tarjeta acepte pagos recurrentes.<br />

          <span>-</span>&nbsp;Intenta utilizando otro método de pago.
        </template>
        <template v-else>
          Ocurrió un error al procesar el pago. Revisa que los datos introducidos sean correctos o prueba con otro
          método de pago.<br />
        </template>
      </div>
    </div>
    <template v-if="getTypePayment == 2 || this.isProvider == 2">
      <div :class="this.isProvider == 2 ? 'conekta-container-full-profile' : 'conekta-container-full'">
        <div class="title"><img class="img_bank" src="/images/banco-azteca-logo.png" width="118" /></div>
        <template v-if="this.isProvider == 2">
          <div id="conektaGolstatsProfile" :key="updateKeyForm"></div>
        </template>
        <template v-else>
          <div id="conektaGolstats" :key="updateKeyForm"></div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="title"><img class="img_bank" src="/images/tarjetas.png" /></div>
      <Input
        label="Nombre como aparece en la tarjeta"
        placeholder=""
        v-model="form.cardUserName"
        @keypress.native="isLetter($event)"
      />
      <div class="input-container">
        <div class="input-container-label">Datos de Tarjeta</div>
        <div class="input-group mb-3">
          <div class="input-group-text">
            <span v-if="isEmpty">
              <img class="card-icons" src="/images/visa.png" />
              <img class="card-icons" src="/images/mastercard.png" />
              <img class="card-icons" style="margin-right: 0px" src="/images/american.png" />
            </span>
            <span v-else>
              <img class="card-icons" :src="cardSelected" style="margin-right: 0px" alt="" />
            </span>
          </div>
          <div id="card-element" class="card-form" style="border-left: none"></div>
        </div>
        <div role="alert" class="input-container-error__message">
          {{ cardErrors }}
        </div>
      </div>
      <div class="field checkbox">
        <BCheckbox v-if="isProfile" :value="true" v-model="isPrimary" type="is-primary">
          Establecer como tarjeta principal
        </BCheckbox>
      </div>
      <JumboButton
        v-if="!isProfile"
        @click.native="getToken('stripe')"
        value="Finalizar Compra"
        class="btn"
        :disabled="disabled"
      />
      <slot></slot>
    </template>
    <div class="field checkbox">
      <div class="checkbox-container-conekta">
        <BCheckbox v-if="isProfile && this.isProvider == 2" :value="true" v-model="isPrimary" type="is-primary">
          Establecer como tarjeta principal
        </BCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Form/Input';
import { BCheckbox } from 'buefy/dist/esm/checkbox';
import JumboButton from '@/components/Form/JumboButton';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import bus from '@/bus';

import { clickBtnFlujoCuentaFinalizarCompra } from '@/utils/analytics';

export default {
  name: 'CardForm',
  props: {
    isProfile: {
      type: Boolean,
      default: false,
    },
    isProvider: {
      type: Number,
      default: 0,
    },
  },
  components: { Input, JumboButton, BCheckbox },
  data: () => ({
    form: {
      cardNumber: '',
      cardUserName: '',
      expirationMonth: '',
      expirationYear: '',
      cvc: '',
    },
    valueDemo: 0,
    stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
    conektaAPIToken: process.env.VUE_APP_CONEKTA_PUBLIC_KEY,
    stripe: '',
    elements: '',
    card: '',
    name: '',
    updateKeyForm: 0,
    addPaymentStatus: 0,
    addPaymentStatusError: '',
    cardErrors: '',
    isPrimary: false,
    disabled: false,
    isEmpty: true,
    defaultCard: '/images/default-card.png',
    cardSelected: '/images/default-card.png',
    acceptedCards: {
      visa: '/images/visa.png',
      mastercard: '/images/mastercard.png',
      amex: '/images/american.png',
    },
    idempotency_key: null,
  }),
  async mounted() {
    if (this.getTypePayment == 2 || this.isProvider == 2) {
      await this.fetchCheckoutID();
      this.includeConekta();
      this.setMsgError(false);
      bus.$on('addNewCard', () => {
        this.getToken('conecta');
      });
    } else {
      this.setMsgError(false);
      this.includeStripe(
        'js.stripe.com/v3/',
        function() {
          this.configureStripe();
        }.bind(this),
      );
      bus.$on('addNewCard', () => {
        this.getToken('stripe');
      });
    }
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('general', ['getSectionAnalytics']),
    ...mapGetters('registerGeneral', [
      'getMsgError',
      'getTxtErrorState',
      'getIdempotencyKeyOld',
      'getMsgTxtError',
      'getCheckoutID',
      'getTokenCard',
      'getTypePayment',
    ]),
  },
  watch: {
    idempotency_key(newKey) {
      this.setIdempotencyKey(newKey);
    },
    async getMsgError() {
      this.updateKeyForm += 1;
      await this.fetchCheckoutID();
      this.includeConekta();
    },
  },
  methods: {
    ...mapMutations('registerGeneral', ['setTokenCard', 'setIdempotencyKey', 'setIdempotencyKeyOld', 'setMsgError']),
    ...mapActions('registerGeneral', ['registerPayment', 'fetchCheckoutID']),
    ...mapActions('profileGeneral', ['addPaymentMethod']),
    ...mapActions('tips', ['fetchScenarios', 'fetchTournamentsWithScenarios']),
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) {
        object.addEventListener(
          'load',
          function(e) {
            callback(null, e);
          },
          false,
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      //eslint-disable-next-line no-undef
      this.stripe = Stripe(this.stripeAPIToken);
      this.elements = this.stripe.elements();
      const style = {
        base: {
          color: '#32325d',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        hideIcon: true,
        style: style,
      });
      this.card.mount('#card-element');
      // Handle real-time validation errors from the card Element.
      let espErrors = {
        incomplete_number: 'Número de tarjeta incompleto',
        incorrect_number: 'El número de tarjeta es incorrecto.',
        invalid_number: 'El número de tarjeta no es válido.',
        invalid_expiry_month: 'El mes de caducidad de la tarjeta no es válido.',
        invalid_expiry_year: 'El año de caducidad de la tarjeta no es válido.',
        invalid_cvc: 'El código de seguridad de la tarjeta no es válido.',
        expired_card: 'La tarjeta ha caducado.',
        incorrect_cvc: 'El código de seguridad de la tarjeta es incorrecto.',
        incorrect_zip: 'Falló la validación del código postal de la tarjeta.',
        card_declined: 'La tarjeta fué rechazada.',
        missing: 'El cliente al que se está cobrando no tiene tarjeta',
        processing_error: 'Ocurrió un error procesando la tarjeta.',
        rate_limit:
          'Ocurrió un error debido a consultar la API demasiado rápido. Por favor,' +
          'avísanos si recibes este error continuamente.',
      };
      this.card.on(
        'change',
        function(event) {
          if (event.complete) {
            this.idempotency_key = uuidv4();
          } else if (event.empty) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
            event.brand in this.acceptedCards
              ? (this.cardSelected = this.acceptedCards[event.brand])
              : (this.cardSelected = this.defaultCard);
          }
          if (event.error) {
            this.cardErrors = espErrors[event.error.code];
          } else {
            this.cardErrors = '';
          }
        }.bind(this),
      );
    },
    async getToken(type, token, status) {
      if (type == 'conecta') {
        if (status) {
          this.disabled = true;
          await this.$store.commit('setIsSectionLoading', true);

          if (this.isProfile) {
            await this.addPaymentMethod(this.isPrimary);
            bus.$emit('addNewCardSuccess');
          } else {
            await this.registerPayment();
            clickBtnFlujoCuentaFinalizarCompra({
              banner: this.getSectionAnalytics,
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            });
            await this.fetchTournamentsWithScenarios(4);
          }
        } else {
          this.updateKeyForm += 1;
          await this.fetchCheckoutID();
          this.includeConekta();
        }
      }

      if (type == 'stripe') {
        this.disabled = true;
        await this.$store.commit('setIsSectionLoading', true);
        try {
          let response = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
          });
          if (response.error) {
            // Inform the user if there was an error.
            this.cardErrors = response.error.message;
          } else {
            if (this.getIdempotencyKeyOld != this.idempotency_key) {
              await this.setTokenCard(response.paymentMethod.id);
            }

            if (this.isProfile) {
              await this.addPaymentMethod(this.isPrimary);
              bus.$emit('addNewCardSuccess');
            } else {
              await this.registerPayment();
              clickBtnFlujoCuentaFinalizarCompra({
                banner: this.getSectionAnalytics,
                is_premium: this.getPremiumAccount,
                golstats: this.getIsGolstats,
              });
              await this.fetchTournamentsWithScenarios(4);
            }
          }
        } catch (error) {
          //delete if not necesary
          this.$emit('error', error);
        } finally {
          this.disabled = false;
          await this.$store.commit('setIsSectionLoading', false);
        }
      }
    },
    includeConekta() {
      const vm = this;
      var nameConektaButton = 'Finalizar Compra';
      if (this.isProvider == 2) {
        nameConektaButton = 'Guardar Tarjeta';

        window.ConektaCheckoutComponents.Card({
          targetIFrame: '#conektaGolstatsProfile',
          //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
          allowTokenization: true,
          checkoutRequestId: this.getCheckoutID, // // Checkout request ID, es el mismo ID generado en el paso 1
          publicKey: this.conektaAPIToken, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys cambiar a .dev
          options: {
            styles: {
              // inputType modifica el diseño del Web Checkout Tokenizer
              //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
              inputType: 'line',
              // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
              //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
              buttonType: 'rounded',
              //Elemento que personaliza el borde de color de los elementos
              states: {
                empty: {
                  borderColor: '#FFAA00', // Código de color hexadecimal para campos vacíos
                },
                invalid: {
                  borderColor: '#FF00E0', // Código de color hexadecimal para campos inválidos
                },
                valid: {
                  borderColor: '#0079c1', // Código de color hexadecimal para campos llenos y válidos
                },
              },
            },
            languaje: 'es', // 'es' Español | 'en' Ingles
            //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
            button: {
              colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
              text: nameConektaButton, //Nombre de la acción en el botón ***Se puede personalizar
              backgroundColor: '#301007', // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
            },
            //Elemento que personaliza el diseño del iframe
            iframe: {
              colorText: '#65A39B', // Código de color hexadecimal para el color de la letra de todos los campos a llenar
              backgroundColor: '#FFFFFF', // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
            },
          },
          onCreateTokenSucceeded: function(token) {
            vm.getToken('conecta', token, true);
          },
          onCreateTokenError: function(error) {
            vm.getToken('conecta', error, false);
          },
        });
      } else {
        window.ConektaCheckoutComponents.Card({
          targetIFrame: '#conektaGolstats',
          //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
          allowTokenization: true,
          checkoutRequestId: this.getCheckoutID, // // Checkout request ID, es el mismo ID generado en el paso 1
          publicKey: this.conektaAPIToken, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys cambiar a .dev
          options: {
            styles: {
              // inputType modifica el diseño del Web Checkout Tokenizer
              //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
              inputType: 'line',
              // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
              //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
              buttonType: 'rounded',
              //Elemento que personaliza el borde de color de los elementos
              states: {
                empty: {
                  borderColor: '#FFAA00', // Código de color hexadecimal para campos vacíos
                },
                invalid: {
                  borderColor: '#FF00E0', // Código de color hexadecimal para campos inválidos
                },
                valid: {
                  borderColor: '#0079c1', // Código de color hexadecimal para campos llenos y válidos
                },
              },
            },
            languaje: 'es', // 'es' Español | 'en' Ingles
            //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
            button: {
              colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
              text: nameConektaButton, //Nombre de la acción en el botón ***Se puede personalizar
              backgroundColor: '#301007', // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
            },
            //Elemento que personaliza el diseño del iframe
            iframe: {
              colorText: '#65A39B', // Código de color hexadecimal para el color de la letra de todos los campos a llenar
              backgroundColor: '#FFFFFF', // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
            },
          },
          onCreateTokenSucceeded: function(token) {
            vm.getToken('conecta', token, true);
          },
          onCreateTokenError: function(error) {
            vm.getToken('conecta', error, false);
          },
        });
      }
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Z a-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container-conekta {
  width: 320px;
  text-align: center;
  margin: 0 auto;
  @media screen and (max-width: 460px) {
    height: 80px;
    margin-top: -15px;
    font-size: 15px;
  }
  @media screen and (max-width: 320px) {
    height: 80px;
    margin-top: -15px;
    font-size: 14px;
  }
}
#conektaGolstats {
  height: 548px;
  margin-top: -35px;
  @media screen and (max-width: 540px) {
    height: 790px;
    margin-top: -15px;
  }
  @media screen and (max-width: 350px) {
    height: 730px;
  }
}
#conektaGolstatsProfile {
  height: 548px;
  margin-top: -25px;
  @media screen and (max-width: 460px) {
    height: 760px;
    margin-top: -15px;
  }
}
.conekta-container-full {
  width: 460px;
  height: auto;
  display: inline-block;
  overflow: hidden;
  margin-top: -25px;
  @media screen and (max-width: 540px) {
    width: 100%;
    height: 840px;
  }
  @media screen and (max-width: 350px) {
    height: 930px;
  }
}
.conekta-container-full-profile {
  width: 460px;
  height: auto;
  display: inline-block;
  overflow: hidden;
  margin-top: -25px;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
}
.img_bank {
  width: 118px;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 540px) {
    width: 98px;
  }
}
.error-payment {
  display: -ms-grid;
  display: grid;
  border-radius: 8px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #ec7171;
  width: 395px;
  min-height: 104px;
  padding-bottom: 20px;
  font-family: 'Avenir-Medium';
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.08px;
  color: #fff;
  text-align: left;
  padding-top: 17px;
  -ms-grid-columns: 59px 336px;
  grid-template-columns: 59px 336px;
  -ms-grid-rows: -webkit-min-content auto;
  -ms-grid-rows: min-content auto;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  margin-bottom: 25px;

  &__img {
    margin-left: 16px;
    margin-top: 3px;
  }
  &__text {
    padding-right: 16px;
    font-family: 'Avenir-Medium';
    &__bold {
      font-family: 'Avenir-Black';
    }
  }
  @media screen and (max-width: 768px) {
    width: 395px;
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 17px;
    display: grid;
    -ms-grid-columns: 59px 336px;
    grid-template-columns: 59px 336px;
    font-size: 15px;
    &__img {
      margin-left: 16px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 17px;
    }
  }
  @media screen and (max-width: 410px) {
    width: 100%;
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 17px;
    display: grid;
    -ms-grid-columns: 59px 336px;
    grid-template-columns: 59px 336px;
    font-size: 15px;
    &__img {
      margin-left: 16px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 17px;
    }
  }
  @media screen and (max-width: 380px) {
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 17px;
    display: grid;
    -ms-grid-columns: 45px 300px;
    grid-template-columns: 45px 300px;
    font-size: 14px;
    &__img {
      margin-left: 9px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 17px;
    }
  }
  @media screen and (max-width: 350px) {
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 22px;
    display: grid;
    -ms-grid-columns: 43px 280px;
    grid-template-columns: 43px 280px;
    font-size: 13px;
    &__img {
      margin-left: 10px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 17px;
    }
  }
  @media screen and (max-width: 330px) {
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 22px;
    display: grid;
    -ms-grid-columns: 42px 262px;
    grid-template-columns: 42px 262px;
    font-size: 12px;
    &__img {
      margin-left: 10px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 17px;
    }
  }
  @media screen and (max-width: 315px) {
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 22px;
    display: grid;
    -ms-grid-columns: 40px 250px;
    grid-template-columns: 40px 250px;
    font-size: 12px;
    &__img {
      margin-left: 9px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 17px;
    }
  }
  @media screen and (max-width: 300px) {
    min-height: 104px;
    padding-bottom: 20px;
    padding-top: 26px;
    display: grid;
    -ms-grid-columns: 45px 240px;
    grid-template-columns: 45px 240px;
    font-size: 11px;
    &__img {
      margin-left: 10px;
      margin-top: 3px;
    }
    &__text {
      padding-right: 14px;
    }
  }
}
.card-icons {
  width: 20px;
  vertical-align: sub;
  margin-right: 8px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .card-form {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.input-group > .form-card-form:focus {
  z-index: 3;
}

.mb-3 {
  margin-bottom: 1rem !important;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 0.25rem;
  border-right: none;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.card-form {
  height: 40px;
  border-radius: 3.5px;
  border: solid 1px #c7c7c7;
  padding-top: 8px;
  padding-left: 10px;
  font-family: Avenir-Regular;
}
.btn {
  width: 90%;
  margin-top: 20px;
}
.checkbox {
  display: flex;
}
.container {
  width: 100%;
  max-width: 94%;
}
.container-stripe {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}
.title {
  font-family: Circular-Std;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.42;
  letter-spacing: -0.17px;
  text-align: center;
  color: #494a4b;
}
.security-info {
  display: flex;
  align-items: flex-end;
}
.date-input {
  display: flex;
  flex-flow: column;

  label {
    text-align: left;
    padding-left: 15px;
  }
  .inputs {
    display: flex;
    flex-flow: row;
    & > div {
      padding-top: 0px;
    }
  }
}
.input-container {
  display: flex;
  padding: 10px 15px;
  flex-flow: column;
  font: Avenir-Regular;
  &-label {
    text-align: left;
    font-family: Circular-Std;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.08px;
    color: #494a4b;
  }
  &-form__input {
    border-radius: 3.5px;
    border: solid 1px #c7c7c7;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    padding-left: 20px;
  }
  &-customized__input {
    background-color: transparent;
    color: #737373;
    border: none;
  }
  &-error__message {
    color: red;
    font-family: Avenir-Regular;
    padding: 2px;
    font-size: 12px;
    text-align: left;
  }
  input {
    width: inherit;
    font-size: 14px;
    color: #3e3e3e;
    text-align: left;
    font-family: Avenir-Regular;
    &::placeholder {
      color: #828282;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>
